@media screen and (max-width: 1024px) {

    /* .flex-wrapper--main {
        margin-top: 6vmin;
    } */

    .main {
        margin: 50px auto 0;
    }

    .main__logo {
        max-width: 100px;
    }

    .main__bg-shape {
        max-width: 22vmin;
    }

}

@media screen and (max-width: 876px) {
    .form-bg__logo {
        display: flex;
      }
    .main::after {
        display: none;
    }
    .wheel-wrapper {
        flex: auto;
    }
    .main {
        flex-direction: column;
    }

    .form-bg {
        justify-content: center;
        align-items: center;
    }

    .form-bg {
        flex-direction: column;
        justify-content: flex-start;
    }

}

@media screen and (max-width: 768px) {
    .main__bg-shapes--desktop {
        display: none;
    }

    .main__bg-shapes--mobile {
        display: block;
    }
    .main__bg-shape {
        max-width: 100%;
    }
    .main__bg-shape--one {
        top: 35%;
    }
    .main__bg-shape--two {
        left: -15%;
        top:  auto;
        bottom: -15%;
    }

    .main__bg-shape--three {
        top: 35%;
        left: auto;
        right: 0;
    }

    .main__bg-shape--four {
        top: auto;
        bottom: -5%;
        right: 0;
    }
}
@media screen and (min-width: 876px) and (max-height: 1200px) {
    /* .player-wrapper {
       margin-top: auto;
    }  */
}

@media screen and (max-width: 576px) {

    .main__logo {
        max-width: 74px;
    }

    .wheel__marker {
        top: 0px;
        width: 38px;
    }

    .notification {
        min-width: auto;
        width: 95%;
    }

    .main {
        margin-top: 0;
    }

    .main__logo {
        margin: 0;
        padding: 25px 0;
    }


}

@media screen and (max-width: 500px) {

    .wheel-wrapper {
        max-width: 350px;
        padding: 24px;
    }

    .wheel__button {
        width: 100px;
        height: 100px;
        font-size: 15px;
        line-height: 115%;
    }

    .wheel__marker {
        width: 25px;
    }

    .notification {
        max-height: 83px;
    }

    .notification__text {
        padding-right: 15px;
    }

    .notification__title {
        font-size: 17px;
    }

    .notification__subtitle {
        font-size: 11px;
    }

    .notification__btn {
        padding: 13px;
    }

    .notification__img {
        max-width: 115px;
    }

}

@media screen and (max-width: 450px) {
    .wheel__button {
        width: 88px;
        height: 88px;
        font-size: 15px;
    }

    .main {
        padding: 0;
    }

}

@media screen and (max-width: 340px) {

    .notification__img {
        max-width: 100px;
    }

    .notification__title {
        font-size: 15px;
    }

    .notification__subtitle {
        font-size: 9px;
    }


}

/*height*/
@media screen and (max-width: 1499px) and (max-height: 768px) {
    /* .flex-wrapper--main {
        margin-top: 3vmin;
    } */
}

@media screen and (min-width: 1499px) and (max-height: 950px) {
    /* .wheel-wrapper {
        max-width: 490px;
    } */
}

/* Если вид не в один столбец и высота меньше 850 - для невысоких экранов*/