.form_state_hidden {
  display: none;
}

.form_state_active {
  animation-name: open;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

@keyframes open {
  0% {
    display: none;
    visibility: hidden;
    opacity: 0;
  }

  100% {
    display: block;
    visibility: visible;
    opacity: 1;
  }
}

.password-input {
  margin-left: 0;
  letter-spacing: 0.8px;
}

/* .register__desc {
  padding: 10px 20px;
} */

.tabs__wrapper {
  display: flex;
  /* overflow: hidden; */
  width: 100% !important;
  height: 35px;
}

.tab {
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  width: 100% !important;
  padding: 17px 0;
  padding-bottom: 23px;
  cursor: pointer;
  color: #ffffff;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 16px;
  font-weight: normal;
  z-index: 100;
  border-bottom: 2px solid #3C3C4C;
  transition: unset !important;
}

.form__wrapper>.form__row {
  position: relative;
  margin-top: 30px;
}

.inp-form {
  /* width: 378px; */
  box-sizing: border-box;
  width: 100%;
  max-width: -webkit-fill-available;
  height: 54px;
  outline: transparent;
  border: 1px solid #3C3C4D;
  border-radius: 5px;
  background-color: transparent !important;
  text-align: left;
  color: #fff;
  font-family: inherit;
  font-size: 16px;
  font-weight: 600
}

.img-mail {
  width: 20px;
  height: 16px;
}

.img-phone {
  width: 15px;
  height: 16px;
}

.email__img {
  display: none;
  position: absolute;
  top: 48px;
  left: 30px;
  width: 28px;
  height: 22px;
}

.password__img {
  display: none;
  position: absolute;
  top: 40px;
  left: 30px;
  width: 30px;
  height: 28px;
}

.password-control {
  display: flex;
  position: absolute;
  top: 0;
  right: 23px;
  width: 30px;
  height: 100%;
  background: url("../../shared/img/view.svg") 0 0 no-repeat;
  /* align-items: center; */
  background-position: center;
}

.currency__img {
  display: none;
  position: absolute;
  left: 30px;
  width: 30px;
  height: 26px;
}

.list-select {
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  width: 90% !important;
  height: 100% !important;
  outline: none;
  border: none;
  background: url("../../shared/img/select.png?as=webp") no-repeat right;
  transform: translate(-50%, -50%) !important;
  font-size: 16px;
  font-weight: 600;
  -webkit-appearance: none;
}

.botom-pop {
  display: flex;
  justify-content: center;
  margin-top: 42px;
  padding-bottom: 8px;
}

.form-btn {
  /* max-width: 378px; */
  height: 76px;
  z-index: 1000;
  width: 100%;
  max-width: 496px;
  max-height: 55px;
  outline: transparent;
  border: none;
  background: linear-gradient(180deg, #9537DF 0%, #5D35F5 100%);
  box-shadow: 0px 10px 20px rgba(112, 48, 213, 0.15);
  border-radius: 5px;
  padding: 15px 28.5px;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 20px;
  font-weight: 700;
}


.register {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  z-index: 100;
  transition: 0.2s ease;
  color: white;
  font-family: "Roboto Condensed", sans-serif;
}

.register__bonus {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 1;
  margin: 0 auto;
  margin-bottom: 24px;
  max-width: 350px;
  max-height: 43px;
  border-radius: 30px;
  padding: 10px 20px;
}

.register__bonus-text {
  margin-right: auto;
  margin-left: 20px;
  text-align: left;
  text-transform: uppercase;
  line-height: 20px;
  font-size: 17px;
  font-weight: 900;
  font-style: italic;
}

.register__bonus--deposit .register__img-wrap::after {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  width: 200%;
  height: 200%;
  /*background-image: url('../../shared/img/effect-1.png?as=webp');*/
  background-position: center;
  background-size: cover;
  transform: translate(-50%, -50%);
  content: "";
}

.register__bonus--casino .register__img-wrap::after {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  width: 200%;
  height: 200%;
  /*background-image: url('../../shared/img/effect-2.png?as=webp');*/
  background-position: center;
  background-size: cover;
  transform: translate(-50%, -50%);
  content: "";
}

.register__img-wrap {
  position: relative;
  z-index: 1;
}

.register__bonus-img {
  margin-top: -10px;
  max-width: 45px;
}



.register__bonus--deposit {
  margin-top: 20px;
  box-shadow: 0px 0px 19px rgba(255, 158, 73, 0.59);
  background: linear-gradient(180deg, #FFC887 0%, #D38044 100%);
}

.register__bonus--casino {
  box-shadow: 0px 0px 19px rgba(219, 73, 255, 0.59);
  background: linear-gradient(180deg, #FF76F1 0%, #731DFF 100%);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #242432 inset;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: white !important;
}

.register__wrapper {
  box-sizing: border-box;
  display: flex;
  position: relative;
  flex: 0 0 534px;
  flex-direction: column;
  width: -moz-max-content;
  min-width: 320px;
  border-radius: 20px;
  box-shadow: 0 3px 20px -10px black;
  background-color: #242432;
  padding: 35px;
}

.register__desc {
  box-sizing: border-box;
  padding-bottom: 30px;
  text-align: center;
  font-family: "Roboto Condensed";
}

.register__desc-title {
  position: relative;
  z-index: 5;
  padding: 12px;
  text-transform: uppercase;
  line-height: 1;
  color: #FFD407;
  font-size: 35px;
  font-stretch: normal;
  font-weight: 900;

}

.register__desc-price {
  position: relative;
  z-index: 5;
  text-transform: uppercase;
  text-shadow: 0px 0px 17px rgba(248, 201, 39, 0.71);
  line-height: 1;
  color: #f8c927;
  font-size: 34px;
  font-stretch: normal;
  font-weight: 900;
  font-style: italic;
}

.register__desc-bg {
  position: absolute;
  top: 27px;
  left: 50px;
  z-index: 1;
  text-align: left;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: normal;
  color: #726950;
  font-family: "Thin", sans-serif;
  font-size: 56px;
  font-stretch: normal;
  font-weight: 100;
  font-style: italic;
}

.register__desc-text {
  margin: 5px auto;
  margin-top: 5px;
  max-width: 327px;
  text-align: center;
  line-height: 130%;
  letter-spacing: normal;
  font-size: 16px;
  font-stretch: normal;
  font-weight: normal;
  text-shadow: none !important;
  color: white !important;
}

.selection {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

/* .tab:hover {
  background-image: linear-gradient(to top, #012347, #052547);
  transition: all 0.5s;
  color: #f4b909;
} */

.tab__text {
  padding-left: 13px;
  white-space: nowrap;
  text-overflow: clip;
  overflow: hidden;
}

.form {
  position: relative;
  transition: all 1s linear;
}

.form__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form__row {
  position: relative;
  margin-top: 45px;
}

.form__row--rules {
  margin-top: 30px;
}

/* .form__row:first-child {
  margin-top: 0;
} */

.phone-prefix__wrap--modified {
  display: flex;
  position: relative;
  align-items: center;
  box-shadow: none;
  background: initial;
  padding: 0;
}

.phone-prefix__flag {
  position: absolute;
  left: 18px;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

select:not(:-internal-list-box) {
  overflow: visible !important;
}

.phone-prefix__select {
  position: absolute;
  left: 30px;
  width: 38px;
  height: 40px;
  border: none;
  background: url("../../shared/img/select.png?as=webp") no-repeat right;
  font-size: 26px;
  font-weight: 600;
  -webkit-appearance: none;
}

.phone-prefix__code {
  display: flex;
  position: absolute;
  top: 0;
  left: 50px;
  align-items: center;
  height: 100%;
  text-align: left;
  line-height: 1.25;
  color: #fff;
  font-family: Roboto Condensed;
  font-size: 15px;
  font-weight: 600;
}

.phone-input {
  padding: 10px 3px 9px 160px;
}

.email-input {
  letter-spacing: 0.8px;
}

.email-input, .password-input, .promocode-input {
  padding-left: 20px;
}

.password-control.view {
  background: url("../../shared/img/no-view.svg") 0 0 no-repeat;
  background-position: center;
}

.inp-form::placeholder {
  opacity: 0.5;
  color: white;
  font-weight: 10;
}

.currency__inp {
  position: relative;
  background-color: #012347;
  padding: 10px 10px 9px 74px;
}

.currency__inp--email {
  margin-left: 0;
}

.formError {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 7px;
  padding-left: 0px;
  color: rgba(214, 51, 51, 1);
  font-size: 12px;
}

.form-btn:hover {
  box-shadow: 0px 11px 27px 0 rgba(112, 48, 213, 0.15);
  transition: all 0.3s;
}

.select2-container {
  position: absolute;
}

.select2-container--default {
  position: absolute;
  top: 19px;
  left: 0;
}

.phone-prefix__select+.select2-container {
  top: 0 !important;
  left: 20px;
  height: 100% !important;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.select2-container--default .select2-selection--single {
  display: flex;
  align-items: center;
  background: transparent;
}

img.undefined {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  top: inherit;
  left: 0;
  margin-top: inherit;
  margin-left: inherit;
  width: inherit;
  height: inherit;
  border-width: inherit;
  border-style: inherit;
  border-color: inherit;
  background: url("../../shared/img/select.png?as=webp") no-repeat right;
  -webkit-appearance: none;
}

.phone-prefix__select+.select2-container--default .select2-selection--single .select2-selection__arrow {
  left: 110px;
  height: 100% !important;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  left: 7px;
  border-width: inherit;
  border-color: inherit;
  background: url("../../shared/img/select.png?as=webp") no-repeat right;
  background-size: 12px;
  transform: rotate(-180deg);
  -webkit-appearance: none;
}

.list-select+.select2 {
  display: flex;
  top: 0;
  right: 0;
  left: auto;
  align-items: center;
  width: 100% !important;
  height: 100%;
}

.list-select+.select2 .select2-selection__rendered {
  position: relative;
  padding-left: 30px;
  color: #fff;
  font-size: 15px;
  font-weight: 300;
}

.list-select+.select2 .select2-selection--single {
  width: 100%;
  height: 100% !important;
}

.phone-prefix__select+.select2-container--default {
  position: absolute;
  top: 44px;
}

.form__checkbox {
  font-size: 15px;
}

/*IE hacks*/


.overlay-bg {
  position: absolute;
  z-index: -30;
  width: 100%;
  height: 100%;
}

.white-text {
  text-shadow: 0 0 5px white !important;
  color: white !important;
}

.list-select,
.currency__img {
  top: 50%;
  transform: translate(0, -50%);
}

.tab.active {
  border-bottom: 2px solid #FFD407;
}

.shape {}

/* .tab:hover {
  background-image: none;
  background-color: #0a2f6c !important;
}

.tab:hover .tab__text {
  color: white;
} */

.register__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.register__img-pic {
  position: absolute;
}

.register__img-pic--one {
  top: 150px;
  left: 540px;
  width: 40%;
}

/*checkbox*/
.form__label {
  margin-bottom: 4px;
  display: inline-flex;
  font-size: 16px;
}

.form__checkbox-item {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.form__checkbox-item+.form__label {
  display: inline-flex;
  align-items: flex-start;
  user-select: none;
}

.form__checkbox-item+.form__label::before {
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 0.5em;
  width: 1em;
  height: 1em;
  border: 1px solid #3C3C4D;
  border-radius: 0.25em;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 65% 65%;
  content: '';
}

.form__checkbox-item:checked+.form__label::before {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACnSURBVHgBnZPBDYMwDEWtTlBx6jZlAQ6dgG4CR6BlDcQWvsA8GeETS7FEAgTIk3yx/X44GCIHgNwW2zI4RuYlrbGNGveoVfwijZzcp6TAdHWzaX7IsheGYdSWedAF2vZPXdeH7af3sqSukr0XpWyIN/NkXRLhTNzIsqDLRfGJihs5DIiJKpujgJioMu8NpmnGCaw3nUKpJ1rhHlX4c8iNc0Qwbv5WZwElzs7zDBY0hQAAAABJRU5ErkJggg==');
  width: 1em;
  height: 1em;
  border-radius: 5px;
  background-size: cover;
}


.form__checkbox-item:required {
  display: none;
}

.form__checkbox-item:required:invalid+.form__label::before {
  border: 1px solid #FF2768;
  border-radius: 5px;
}

.form__label--rules {
  margin: 0;
  color: #9B9BB2;
  max-width: 300px;
}

/*checkbox*/

/* select */

/* .select2-container--open .select2-dropdown {
  width: 225px !important;
  background-color: #0a2f6c;
  border-radius: 10px;
  border: 1px solid #3C3C4D;
  color: white;
}

img.undefined {
  width: 24px !important;
  height: 24px !important;
}

.select2-search--dropdown .select2-search__field {
  border: 1px solid #164b9a;
  background-color: #0a2f6c;
  outline: none;
  color: white;
}


.select2-results__options::-webkit-scrollbar {
  width: 4px;
}

.select2-results__options::-webkit-scrollbar-track {
  background: transparent;
}

.select2-results__options::-webkit-scrollbar-thumb {
  background: #164b9a;
  border-radius: 30px;
} */