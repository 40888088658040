.select2-container {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: middle;
  margin: 0;
  display: inline-block;
  position: relative;
}

.select2-container .select2-selection--single {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  height: 28px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: block;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 8px;
  padding-right: 20px;
  display: block;
  overflow: hidden;
}

.select2-container .select2-selection--single .select2-selection__clear {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  font-size: 1em;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-left: 20px;
  padding-right: 8px;
}

.select2-container .select2-selection--multiple {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  min-height: 32px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: block;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  padding: 0;
  list-style: none;
  display: inline;
}

.select2-container .select2-selection--multiple .select2-selection__clear {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  font-size: 1em;
}

.select2-container .select2-search--inline .select2-search__field {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 100%;
  resize: none;
  height: 18px;
  vertical-align: bottom;
  word-break: keep-all;
  border: none;
  margin-top: 5px;
  margin-left: 5px;
  padding: 0;
  font-family: sans-serif;
  font-size: 100%;
  overflow: hidden;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  z-index: 1051;
  background-color: #fff;
  border: 1px solid #aaa;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  display: block;
  position: absolute;
  left: -100000px;
}

.select2-results {
  display: block;
}

.select2-results__options {
  margin: 0;
  padding: 0;
  list-style: none;
}

.select2-results__option {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 6px;
}

.select2-results__option--selectable {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0;
  -webkit-border-top-right-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  padding: 4px;
  display: block;
}

.select2-search--dropdown .select2-search__field {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  -webkit-filter: alpha(opacity= 0);
  filter: alpha(opacity= 0);
  background-color: #fff;
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

.select2-hidden-accessible {
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  width: 1px !important;
  white-space: nowrap !important;
  border: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  height: 26px;
  margin-right: 20px;
  padding-right: 0;
  font-weight: bold;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  width: 20px;
  position: absolute;
  top: 1px;
  right: 1px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  height: 0;
  width: 0;
  border: 4px solid rgba(0, 0, 0, 0);
  border-top: 5px solid #888;
  border-bottom-width: 0;
  margin-top: -2px;
  margin-left: -4px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  cursor: default;
  background-color: #eee;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-width: 0 4px 5px;
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #888;
}

.select2-container--default .select2-selection--multiple {
  cursor: text;
  background-color: #fff;
  border: 1px solid #aaa;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  padding-bottom: 5px;
  padding-right: 5px;
  position: relative;
}

.select2-container--default .select2-selection--multiple.select2-selection--clearable {
  padding-right: 25px;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  height: 20px;
  margin-top: 5px;
  margin-right: 10px;
  padding: 1px;
  font-weight: bold;
  position: absolute;
  right: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 100%;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  margin-top: 5px;
  margin-left: 5px;
  padding: 0 0 0 20px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-right: 1px solid #aaa;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 0 4px;
  font-size: 1em;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus {
  color: #333;
  background-color: #f1f1f1;
  outline: none;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  border-left: 1px solid #aaa;
  border-right: none;
  -webkit-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__clear {
  float: left;
  margin-left: 10px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 1px solid #000;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  cursor: default;
  background-color: #eee;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0;
  -webkit-border-top-right-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  box-shadow: none;
  -webkit-appearance: textfield;
  background: none;
  border: none;
  outline: 0;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--group {
  padding: 0;
}

.select2-container--default .select2-results__option--disabled {
  color: #999;
}

.select2-container--default .select2-results__option--selected {
  background-color: #ddd;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  color: #fff;
  background-color: #5897fb;
}

.select2-container--default .select2-results__group {
  cursor: default;
  padding: 6px;
  display: block;
}

.select2-container--classic .select2-selection--single {
  -webkit-filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#FFFFFFFF", endColorstr= "#FFEEEEEE", GradientType= 0);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#FFFFFFFF", endColorstr= "#FFEEEEEE", GradientType= 0);
  background-color: #f7f7f7;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, color-stop(.5, #fff), to(#eee));
  background-image: -webkit-linear-gradient(#fff 50%, #eee 100%);
  background-image: -moz-linear-gradient(#fff 50%, #eee 100%);
  background-image: linear-gradient(#fff 50%, #eee 100%);
  background-repeat: repeat-x;
  border: 1px solid #aaa;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  outline: 0;
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  height: 26px;
  margin-right: 20px;
  font-weight: bold;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  height: 26px;
  width: 20px;
  -webkit-filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#FFEEEEEE", endColorstr= "#FFCCCCCC", GradientType= 0);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#FFEEEEEE", endColorstr= "#FFCCCCCC", GradientType= 0);
  background-color: #ddd;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, color-stop(.5, #eee), to(#ccc));
  background-image: -webkit-linear-gradient(#eee 50%, #ccc 100%);
  background-image: -moz-linear-gradient(#eee 50%, #ccc 100%);
  background-image: linear-gradient(#eee 50%, #ccc 100%);
  background-repeat: repeat-x;
  border: none;
  border-left: 1px solid #aaa;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  position: absolute;
  top: 1px;
  right: 1px;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  height: 0;
  width: 0;
  border: 4px solid rgba(0, 0, 0, 0);
  border-top: 5px solid #888;
  border-bottom-width: 0;
  margin-top: -2px;
  margin-left: -4px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  -webkit-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: none;
  border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-width: 0 4px 5px;
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #888;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  -webkit-filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#FFFFFFFF", endColorstr= "#FFEEEEEE", GradientType= 0);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#FFFFFFFF", endColorstr= "#FFEEEEEE", GradientType= 0);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fff), color-stop(.5, #eee));
  background-image: -webkit-linear-gradient(#fff 0%, #eee 50%);
  background-image: -moz-linear-gradient(#fff 0%, #eee 50%);
  background-image: linear-gradient(#fff 0%, #eee 50%);
  background-repeat: repeat-x;
  border-top: none;
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0;
  -webkit-border-top-right-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  -webkit-filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#FFEEEEEE", endColorstr= "#FFFFFFFF", GradientType= 0);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#FFEEEEEE", endColorstr= "#FFFFFFFF", GradientType= 0);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, color-stop(.5, #eee), to(#fff));
  background-image: -webkit-linear-gradient(#eee 50%, #fff 100%);
  background-image: -moz-linear-gradient(#eee 50%, #fff 100%);
  background-image: linear-gradient(#eee 50%, #fff 100%);
  background-repeat: repeat-x;
  border-bottom: none;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
}

.select2-container--classic .select2-selection--multiple {
  cursor: text;
  background-color: #fff;
  border: 1px solid #aaa;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  outline: 0;
  padding-bottom: 5px;
  padding-right: 5px;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  margin-top: 5px;
  margin-left: 5px;
  padding: 0;
  display: inline-block;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 0 4px;
  font-size: 1em;
  font-weight: bold;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
  outline: none;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  -webkit-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0;
  -webkit-border-top-right-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  box-shadow: none;
  outline: 0;
}

.select2-container--classic .select2-dropdown {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0);
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option--group {
  padding: 0;
}

.select2-container--classic .select2-results__option--disabled {
  color: gray;
}

.select2-container--classic .select2-results__option--highlighted.select2-results__option--selectable {
  color: #fff;
  background-color: #3875d7;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  padding: 6px;
  display: block;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"], input.phone-input {
  -moz-appearance: textfield;
}

.select2-container .select2-selection--single {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  height: 28px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: block;
}

.select2-container--default .select2-selection--single {
  height: 60px;
  background-color: #fff;
  border: 0 solid #aaa;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  outline: none;
}

.phone-prefix__select + .select2-container, .phone-prefix__select + .select2-container .select2-selection, .phone-prefix__select + .select2-container .selection {
  height: 100%;
  outline: none;
}

.phone-prefix__select + .select2-container {
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  -webkit-flex: none;
  flex: none;
}

.phone-prefix__select + .select2-container--default .select2-selection--single .select2-selection__arrow {
  right: 0;
}

.phone-prefix__select + .select2-container--default {
  height: 30px !important;
}

.phone-prefix__wrap {
  background: #fff;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 20px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, .24);
  box-shadow: 0 2px 2px rgba(0, 0, 0, .24);
}

.phone-prefix__code {
  color: #000;
  text-align: left;
  margin-right: 10px;
  font-size: 20px;
  font-weight: 500;
  line-height: 31px;
  display: inline-block;
}

.phone-prefix__code-select {
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
}

.phone-prefix__flag {
  width: 30px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.list-select {
  background-color: #fff;
  border: 1px solid #000;
  font-size: 20px;
}

.list-select + .select2 {
  margin-bottom: 8px;
}

.list-select + .select2 .select2-selection--single {
  height: 60px;
  text-align: left;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
}

.list-select + .select2 .select2-selection__rendered {
  padding-left: 30px;
  font-size: 20px;
}

.list-select + .select2 .select2-selection__arrow {
  height: 100%;
  padding-right: 20px;
}

.select2 * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.select2-results__option[aria-selected] {
  color: #000 !important;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  min-height: 100%;
  scroll-behavior: smooth;
  background-repeat: no-repeat;
  background-size: cover;
}

.page {
  min-height: 100vh;
  color: #fff;
  background: #121218;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  margin: 0;
  font-family: Roboto Condensed, sans-serif;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
  position: relative;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 1vmin;
}

body::-webkit-scrollbar-track {
  background: #dde4ec;
}

body::-webkit-scrollbar-thumb {
  background: #9537df;
  -webkit-border-radius: 30px;
  border-radius: 30px;
}

.header {
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
}

.header__logo {
  padding: 40px 0 20px;
}

.main {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  max-width: 1350px;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-self: center;
  align-self: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
  position: relative;
}

.main__title {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  z-index: 2;
  font-size: max(1.75rem, min(.4844rem + 5.625vw, 3.4375rem));
}

.main__subtitle {
  text-align: center;
  text-transform: uppercase;
  color: #ffd802;
  z-index: 2;
  padding: 0 10px;
  font-size: max(1.1875rem, min(.9063rem + 1.25vw, 1.5625rem));
  font-weight: 700;
  line-height: 29px;
}

.main__counter {
  z-index: 2;
  text-align: center;
  color: #9b9bb2;
  background: #3c3c4d;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  margin-top: 40px;
  margin-bottom: 25px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 400;
  -webkit-box-shadow: 0 0 40px rgba(0, 0, 0, .25);
  box-shadow: 0 0 40px rgba(0, 0, 0, .25);
}

.main__bg-elem {
  pointer-events: none;
  min-height: 100%;
  width: 100%;
}

.main__bg-shape {
  -webkit-animation: 6s linear infinite float;
  -moz-animation: 6s linear infinite float;
  animation: 6s linear infinite float;
  position: absolute;
}

.main__bg-shape--one {
  top: 0;
  left: 0;
}

.main__bg-shape--two {
  top: 40%;
  left: -5%;
}

.main__bg-shape--three {
  bottom: 0;
  left: 0;
}

.main__bg-shape--four {
  top: 20%;
  right: 0;
}

.main__bg-shape--five {
  top: 50%;
  right: 0;
}

.main__bg-shape--six {
  bottom: -5%;
  right: 0;
}

.main__bg-shapes--mobile {
  display: none;
}

.bonus__item--accent {
  background: -webkit-gradient(linear, 0 0, 0 100%, from(#9437e0), to(#5e35f5)) !important;
  background: -webkit-linear-gradient(#9437e0 0%, #5e35f5 100%) !important;
  background: -moz-linear-gradient(#9437e0 0%, #5e35f5 100%) !important;
  background: linear-gradient(#9437e0 0%, #5e35f5 100%) !important;
}

.form-bg {
  opacity: 0;
  z-index: -10000;
  width: 100%;
  min-height: 100vh;
  min-height: 100%;
  backdrop-filter: blur(12px);
  background: rgba(12, 17, 29, .7);
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 15px;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.page-wrapper {
  min-height: 100vh;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
  overflow: hidden;
}

.wheel {
  height: 100%;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
  position: relative;
}

.wheel-wrapper {
  max-width: 566px;
  height: auto;
  background-image: url("wheel-border.9146801d.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-self: center;
  align-self: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 40px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
  position: relative;
  overflow: hidden;
}

.wheel-wrapper:before {
  width: 100%;
  height: 100%;
  content: "";
  background-image: url("wheel-lights.846cc43d.webp");
  background-position: center;
  background-size: cover;
  -webkit-animation: .6s linear infinite blink;
  -moz-animation: .6s linear infinite blink;
  animation: .6s linear infinite blink;
  position: absolute;
  top: 0;
  left: 0;
}

.wheel__marker {
  z-index: 3;
  width: 44px;
  -webkit-filter: drop-shadow(0 4px 8px #fff);
  filter: drop-shadow(0 4px 8px #fff);
  position: absolute;
  top: 3px;
}

.wheel__item {
  z-index: 1;
  width: 100%;
  height: 100%;
}

.wheel__button {
  z-index: 2;
  width: 28%;
  height: 28%;
  cursor: pointer;
  text-align: center;
  text-align: center;
  text-transform: uppercase;
  text-transform: uppercase;
  letter-spacing: 0;
  color: #121218;
  background: -webkit-gradient(linear, 0 0, 0 100%, from(#ffd600), to(#ffba00));
  background: -webkit-linear-gradient(#ffd600 0%, #ffba00 100%);
  background: -moz-linear-gradient(#ffd600 0%, #ffba00 100%);
  background: linear-gradient(#ffd600 0%, #ffba00 100%);
  border: none;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  outline: none;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  font-family: Roboto Condensed;
  font-size: 24.9437px;
  font-style: normal;
  font-weight: 900;
  line-height: 29px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-box-shadow: 0 9.77049px 41.8735px 4.18735px rgba(255, 212, 7, .66);
  box-shadow: 0 9.77049px 41.8735px 4.18735px rgba(255, 212, 7, .66);
}

.main__logo {
  width: 100%;
  max-width: 123px;
  margin-bottom: 41px;
}

.btn {
  cursor: pointer;
  border: none;
  outline: none;
  font-family: Roboto Condensed, sans-serif;
}

.notification {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 15;
  min-width: 550px;
  max-height: 99px;
  background: -webkit-gradient(linear, 0 0, 0 100%, from(#38ba6d), to(#2273b9));
  background: -webkit-linear-gradient(#38ba6d 0%, #2273b9 100%);
  background: -moz-linear-gradient(#38ba6d 0%, #2273b9 100%);
  background: linear-gradient(#38ba6d 0%, #2273b9 100%);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding-right: 55px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
  position: fixed;
  bottom: -50%;
  left: 50%;
  overflow: hidden;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.notification__btn {
  text-transform: uppercase;
  color: #121218;
  background: -webkit-gradient(linear, 0 0, 0 100%, from(#ffd600), to(#ffba00));
  background: -webkit-linear-gradient(#ffd600 0%, #ffba00 100%);
  background: -moz-linear-gradient(#ffd600 0%, #ffba00 100%);
  background: linear-gradient(#ffd600 0%, #ffba00 100%);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  padding: 13px 49px;
  font-size: 16px;
  font-weight: 700;
  -webkit-box-shadow: 0 10px 20px rgba(255, 212, 7, .15);
  box-shadow: 0 10px 20px rgba(255, 212, 7, .15);
}

.notification__img {
  object-fit: contain;
  object-position: -25px -10px;
}

.notification__text {
  text-transform: uppercase;
  padding-right: 50px;
}

.notification__subtitle {
  font-size: 13px;
  font-weight: 400;
}

.notification__title {
  white-space: nowrap;
  z-index: 2;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 700;
  line-height: 100%;
}

@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-moz-keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.form-wrapper {
  width: 100%;
  max-width: 534px;
}

.bonus {
  gap: 10px;
  padding-top: 15px;
  padding-bottom: 30px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
}

.bonus__img {
  width: 90px;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 15px;
}

.bonus__img--left {
  background-image: url("notif1.d9c05050.webp");
  background-position: 48% 73%;
}

.bonus__img--right {
  background-image: url("notif2.e9199037.webp");
}

.bonus__item {
  height: 99px;
  background: -webkit-gradient(linear, 0 0, 0 100%, from(#38ba6d), to(#2273b9));
  background: -webkit-linear-gradient(#38ba6d 0%, #2273b9 100%);
  background: -moz-linear-gradient(#38ba6d 0%, #2273b9 100%);
  background: linear-gradient(#38ba6d 0%, #2273b9 100%);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  -webkit-flex: 0 50%;
  flex: 0 50%;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
  position: relative;
}

.bonus__subtitle {
  text-transform: uppercase;
  font-size: 13px;
}

.bonus__title {
  text-transform: uppercase;
  font-size: 20px;
}

.no-padding {
  height: 100vh;
}

.notification.notification--active {
  opacity: 1;
  -webkit-animation: 1s linear fadeInUp;
  -moz-animation: 1s linear fadeInUp;
  animation: 1s linear fadeInUp;
  bottom: 1%;
}

.wheel.bonus1-overlay:before {
  content: "";
  width: 100%;
  height: 100%;
  z-index: 3;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.wheel.bonus2-overlay:before {
  content: "";
  width: 100%;
  height: 100%;
  z-index: 3;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.bonus1 {
  -webkit-transition: all 4.5s cubic-bezier(0, -.08, 0, .99);
  -moz-transition: all 4.5s cubic-bezier(0, -.08, 0, .99);
  transition: all 4.5s cubic-bezier(0, -.08, 0, .99);
  -webkit-transform: rotate(1080deg);
  -moz-transform: rotate(1080deg);
  -ms-transform: rotate(1080deg);
  transform: rotate(1080deg);
}

.bonus2 {
  -webkit-transition: all 4.5s cubic-bezier(0, -.08, 0, .99);
  -moz-transition: all 4.5s cubic-bezier(0, -.08, 0, .99);
  transition: all 4.5s cubic-bezier(0, -.08, 0, .99);
  -webkit-transform: rotate(2610deg);
  -moz-transform: rotate(2610deg);
  -ms-transform: rotate(2610deg);
  transform: rotate(2610deg);
}

.form-bg.showing {
  opacity: 1;
  z-index: 1000;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-animation: 1.5s linear forwards showing;
  -moz-animation: 1.5s linear forwards showing;
  animation: 1.5s linear forwards showing;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    bottom: -50%;
  }

  to {
    opacity: 1;
    bottom: 3%;
  }
}

@-moz-keyframes fadeInUp {
  from {
    opacity: 0;
    bottom: -50%;
  }

  to {
    opacity: 1;
    bottom: 3%;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    bottom: -50%;
  }

  to {
    opacity: 1;
    bottom: 3%;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale(1)translate(-50%, -50%);
    -moz-transform: scale(1)translate(-50%, -50%);
    -ms-transform: scale(1)translate(-50%, -50%);
    transform: scale(1)translate(-50%, -50%);
    -webkit-box-shadow: 0 7px 15px 3px rgba(255, 78, 1, .3);
    box-shadow: 0 7px 15px 3px rgba(255, 78, 1, .3);
  }

  to {
    -webkit-transform: scale(1.1)translate(-50%, -50%);
    -moz-transform: scale(1.1)translate(-50%, -50%);
    -ms-transform: scale(1.1)translate(-50%, -50%);
    transform: scale(1.1)translate(-50%, -50%);
    -webkit-box-shadow: 0 7px 30px 3px rgba(255, 78, 1, .66);
    box-shadow: 0 7px 30px 3px rgba(255, 78, 1, .66);
  }
}

@-moz-keyframes pulse {
  from {
    -webkit-transform: scale(1)translate(-50%, -50%);
    -moz-transform: scale(1)translate(-50%, -50%);
    -ms-transform: scale(1)translate(-50%, -50%);
    transform: scale(1)translate(-50%, -50%);
    -webkit-box-shadow: 0 7px 15px 3px rgba(255, 78, 1, .3);
    box-shadow: 0 7px 15px 3px rgba(255, 78, 1, .3);
  }

  to {
    -webkit-transform: scale(1.1)translate(-50%, -50%);
    -moz-transform: scale(1.1)translate(-50%, -50%);
    -ms-transform: scale(1.1)translate(-50%, -50%);
    transform: scale(1.1)translate(-50%, -50%);
    -webkit-box-shadow: 0 7px 30px 3px rgba(255, 78, 1, .66);
    box-shadow: 0 7px 30px 3px rgba(255, 78, 1, .66);
  }
}

@keyframes pulse {
  from {
    -webkit-transform: scale(1)translate(-50%, -50%);
    -moz-transform: scale(1)translate(-50%, -50%);
    -ms-transform: scale(1)translate(-50%, -50%);
    transform: scale(1)translate(-50%, -50%);
    -webkit-box-shadow: 0 7px 15px 3px rgba(255, 78, 1, .3);
    box-shadow: 0 7px 15px 3px rgba(255, 78, 1, .3);
  }

  to {
    -webkit-transform: scale(1.1)translate(-50%, -50%);
    -moz-transform: scale(1.1)translate(-50%, -50%);
    -ms-transform: scale(1.1)translate(-50%, -50%);
    transform: scale(1.1)translate(-50%, -50%);
    -webkit-box-shadow: 0 7px 30px 3px rgba(255, 78, 1, .66);
    box-shadow: 0 7px 30px 3px rgba(255, 78, 1, .66);
  }
}

@-webkit-keyframes pulseBtn {
  from {
    -webkit-box-shadow: 0 7px 15px 3px rgba(255, 78, 1, .3);
    box-shadow: 0 7px 15px 3px rgba(255, 78, 1, .3);
  }

  to {
    -webkit-box-shadow: 0 7px 30px 3px rgba(255, 78, 1, .66);
    box-shadow: 0 7px 30px 3px rgba(255, 78, 1, .66);
  }
}

@-moz-keyframes pulseBtn {
  from {
    -webkit-box-shadow: 0 7px 15px 3px rgba(255, 78, 1, .3);
    box-shadow: 0 7px 15px 3px rgba(255, 78, 1, .3);
  }

  to {
    -webkit-box-shadow: 0 7px 30px 3px rgba(255, 78, 1, .66);
    box-shadow: 0 7px 30px 3px rgba(255, 78, 1, .66);
  }
}

@keyframes pulseBtn {
  from {
    -webkit-box-shadow: 0 7px 15px 3px rgba(255, 78, 1, .3);
    box-shadow: 0 7px 15px 3px rgba(255, 78, 1, .3);
  }

  to {
    -webkit-box-shadow: 0 7px 30px 3px rgba(255, 78, 1, .66);
    box-shadow: 0 7px 30px 3px rgba(255, 78, 1, .66);
  }
}

@-webkit-keyframes float {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@-moz-keyframes float {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes float {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fadeInUp {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes showing {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-moz-keyframes showing {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes showing {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-webkit-keyframes smoke {
  0% {
    opacity: 0;
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0);
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate(10%, -20%);
    -moz-transform: translate(10%, -20%);
    -ms-transform: translate(10%, -20%);
    transform: translate(10%, -20%);
  }
}

@-moz-keyframes smoke {
  0% {
    opacity: 0;
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0);
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate(10%, -20%);
    -moz-transform: translate(10%, -20%);
    -ms-transform: translate(10%, -20%);
    transform: translate(10%, -20%);
  }
}

@keyframes smoke {
  0% {
    opacity: 0;
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0);
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate(10%, -20%);
    -moz-transform: translate(10%, -20%);
    -ms-transform: translate(10%, -20%);
    transform: translate(10%, -20%);
  }
}

@media screen and (max-width: 1024px) {
  .main {
    margin: 50px auto 0;
  }

  .main__logo {
    max-width: 100px;
  }

  .main__bg-shape {
    max-width: 22vmin;
  }
}

@media screen and (max-width: 876px) {
  .form-bg__logo {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: flex;
  }

  .main:after {
    display: none;
  }

  .wheel-wrapper {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: auto;
    flex: auto;
  }

  .main {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .form-bg {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -moz-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -moz-box-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .main__bg-shapes--desktop {
    display: none;
  }

  .main__bg-shapes--mobile {
    display: block;
  }

  .main__bg-shape {
    max-width: 100%;
  }

  .main__bg-shape--one {
    top: 35%;
  }

  .main__bg-shape--two {
    top: auto;
    bottom: -15%;
    left: -15%;
  }

  .main__bg-shape--three {
    top: 35%;
    left: auto;
    right: 0;
  }

  .main__bg-shape--four {
    top: auto;
    bottom: -5%;
    right: 0;
  }
}

@media screen and (max-width: 576px) {
  .main__logo {
    max-width: 74px;
  }

  .wheel__marker {
    width: 38px;
    top: 0;
  }

  .notification {
    min-width: auto;
    width: 95%;
  }

  .main {
    margin-top: 0;
  }

  .main__logo {
    margin: 0;
    padding: 25px 0;
  }
}

@media screen and (max-width: 500px) {
  .wheel-wrapper {
    max-width: 350px;
    padding: 24px;
  }

  .wheel__button {
    width: 100px;
    height: 100px;
    font-size: 15px;
    line-height: 115%;
  }

  .wheel__marker {
    width: 25px;
  }

  .notification {
    max-height: 83px;
  }

  .notification__text {
    padding-right: 15px;
  }

  .notification__title {
    font-size: 17px;
  }

  .notification__subtitle {
    font-size: 11px;
  }

  .notification__btn {
    padding: 13px;
  }

  .notification__img {
    max-width: 115px;
  }
}

@media screen and (max-width: 450px) {
  .wheel__button {
    width: 88px;
    height: 88px;
    font-size: 15px;
  }

  .main {
    padding: 0;
  }
}

@media screen and (max-width: 340px) {
  .notification__img {
    max-width: 100px;
  }

  .notification__title {
    font-size: 15px;
  }

  .notification__subtitle {
    font-size: 9px;
  }
}

.form_state_hidden {
  display: none;
}

.form_state_active {
  -webkit-animation-name: open;
  -moz-animation-name: open;
  animation-name: open;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-out;
  -moz-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-direction: normal;
  -moz-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes open {
  0% {
    visibility: hidden;
    opacity: 0;
    display: none;
  }

  100% {
    visibility: visible;
    opacity: 1;
    display: block;
  }
}

@-moz-keyframes open {
  0% {
    visibility: hidden;
    opacity: 0;
    display: none;
  }

  100% {
    visibility: visible;
    opacity: 1;
    display: block;
  }
}

@keyframes open {
  0% {
    visibility: hidden;
    opacity: 0;
    display: none;
  }

  100% {
    visibility: visible;
    opacity: 1;
    display: block;
  }
}

.password-input {
  letter-spacing: .8px;
  margin-left: 0;
}

.tabs__wrapper {
  height: 35px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
  width: 100% !important;
}

.tab {
  cursor: pointer;
  color: #fff;
  z-index: 100;
  border-bottom: 2px solid #3c3c4c;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 17px 0 23px;
  font-family: Roboto Condensed, sans-serif;
  font-size: 16px;
  font-weight: normal;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
  overflow: hidden;
  width: 100% !important;
  -webkit-transition: unset !important;
  -moz-transition: unset !important;
  transition: unset !important;
}

.form__wrapper > .form__row {
  margin-top: 30px;
  position: relative;
}

.inp-form {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  max-width: -webkit-fill-available;
  height: 54px;
  text-align: left;
  color: #fff;
  border: 1px solid #3c3c4d;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  outline: rgba(0, 0, 0, 0);
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0) !important;
}

.img-mail {
  width: 20px;
  height: 16px;
}

.img-phone {
  width: 15px;
  height: 16px;
}

.email__img {
  width: 28px;
  height: 22px;
  display: none;
  position: absolute;
  top: 48px;
  left: 30px;
}

.password__img {
  width: 30px;
  height: 28px;
  display: none;
  position: absolute;
  top: 40px;
  left: 30px;
}

.password-control {
  width: 30px;
  height: 100%;
  background: url("view.8a969ecd.svg") center no-repeat;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
  position: absolute;
  top: 0;
  right: 23px;
}

.currency__img {
  width: 30px;
  height: 26px;
  display: none;
  position: absolute;
  left: 30px;
}

.list-select {
  -webkit-appearance: none;
  background: url("select.0cd151cc.webp") 100% no-repeat;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  width: 90% !important;
  height: 100% !important;
  top: 50% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
  -moz-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}

.botom-pop {
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin-top: 42px;
  padding-bottom: 8px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
}

.form-btn {
  height: 76px;
  z-index: 1000;
  width: 100%;
  max-width: 496px;
  max-height: 55px;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background: -webkit-gradient(linear, 0 0, 0 100%, from(#9537df), to(#5d35f5));
  background: -webkit-linear-gradient(#9537df 0%, #5d35f5 100%);
  background: -moz-linear-gradient(#9537df 0%, #5d35f5 100%);
  background: linear-gradient(#9537df 0%, #5d35f5 100%);
  border: none;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  outline: rgba(0, 0, 0, 0);
  padding: 15px 28.5px;
  font-family: Roboto Condensed, sans-serif;
  font-size: 20px;
  font-weight: 700;
  -webkit-box-shadow: 0 10px 20px rgba(112, 48, 213, .15);
  box-shadow: 0 10px 20px rgba(112, 48, 213, .15);
}

.register {
  z-index: 100;
  color: #fff;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  font-family: Roboto Condensed, sans-serif;
  -webkit-transition: all .2s;
  -moz-transition: all .2s;
  transition: all .2s;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
  position: relative;
}

.register__bonus {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 1;
  max-width: 350px;
  max-height: 43px;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0 auto 24px;
  padding: 10px 20px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
}

.register__bonus-text {
  text-align: left;
  text-transform: uppercase;
  margin-left: 20px;
  margin-right: auto;
  font-size: 17px;
  font-style: italic;
  font-weight: 900;
  line-height: 20px;
}

.register__bonus--deposit .register__img-wrap:after {
  z-index: -1;
  width: 200%;
  height: 200%;
  content: "";
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.register__bonus--casino .register__img-wrap:after {
  z-index: -1;
  width: 200%;
  height: 200%;
  content: "";
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.register__img-wrap {
  z-index: 1;
  position: relative;
}

.register__bonus-img {
  max-width: 45px;
  margin-top: -10px;
}

.register__bonus--deposit {
  background: -webkit-gradient(linear, 0 0, 0 100%, from(#ffc887), to(#d38044));
  background: -webkit-linear-gradient(#ffc887 0%, #d38044 100%);
  background: -moz-linear-gradient(#ffc887 0%, #d38044 100%);
  background: linear-gradient(#ffc887 0%, #d38044 100%);
  margin-top: 20px;
  -webkit-box-shadow: 0 0 19px rgba(255, 158, 73, .59);
  box-shadow: 0 0 19px rgba(255, 158, 73, .59);
}

.register__bonus--casino {
  background: -webkit-gradient(linear, 0 0, 0 100%, from(#ff76f1), to(#731dff));
  background: -webkit-linear-gradient(#ff76f1 0%, #731dff 100%);
  background: -moz-linear-gradient(#ff76f1 0%, #731dff 100%);
  background: linear-gradient(#ff76f1 0%, #731dff 100%);
  -webkit-box-shadow: 0 0 19px rgba(219, 73, 255, .59);
  box-shadow: 0 0 19px rgba(219, 73, 255, .59);
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus {
  -webkit-transition: background-color 5000s ease-in-out;
  -moz-transition: background-color 5000s ease-in-out;
  transition: background-color 5000s ease-in-out;
  -webkit-box-shadow: inset 0 0 0 1000px #242432;
  -webkit-text-fill-color: white !important;
}

.register__wrapper {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: -moz-max-content;
  min-width: 320px;
  background-color: #242432;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  -webkit-flex: 0 0 534px;
  flex: 0 0 534px;
  padding: 35px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
  position: relative;
  -webkit-box-shadow: 0 3px 20px -10px #000;
  box-shadow: 0 3px 20px -10px #000;
}

.register__desc {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  padding-bottom: 30px;
  font-family: Roboto Condensed;
}

.register__desc-title {
  z-index: 5;
  text-transform: uppercase;
  color: #ffd407;
  padding: 12px;
  font-size: 35px;
  font-weight: 900;
  font-stretch: normal;
  line-height: 1;
  position: relative;
}

.register__desc-price {
  z-index: 5;
  text-transform: uppercase;
  text-shadow: 0 0 17px rgba(248, 201, 39, .71);
  color: #f8c927;
  font-size: 34px;
  font-style: italic;
  font-weight: 900;
  font-stretch: normal;
  line-height: 1;
  position: relative;
}

.register__desc-bg {
  z-index: 1;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: normal;
  color: #726950;
  font-family: Thin, sans-serif;
  font-size: 56px;
  font-style: italic;
  font-weight: 100;
  font-stretch: normal;
  line-height: 1;
  position: absolute;
  top: 27px;
  left: 50px;
}

.register__desc-text {
  max-width: 327px;
  text-align: center;
  letter-spacing: normal;
  margin: 5px auto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 130%;
  text-shadow: none !important;
  color: #fff !important;
}

.selection {
  width: 100%;
  height: 100%;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
}

.tab__text {
  white-space: nowrap;
  -o-text-overflow: clip;
  text-overflow: clip;
  padding-left: 13px;
  overflow: hidden;
}

.form {
  -webkit-transition: all 1s linear;
  -moz-transition: all 1s linear;
  transition: all 1s linear;
  position: relative;
}

.form__wrapper {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
}

.form__row {
  margin-top: 45px;
  position: relative;
}

.form__row--rules {
  margin-top: 30px;
}

.phone-prefix__wrap--modified {
  box-shadow: none;
  background: initial;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
  position: relative;
}

.phone-prefix__flag {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  left: 18px;
}

select:not(:-internal-list-box) {
  overflow: visible !important;
}

.phone-prefix__select {
  width: 38px;
  height: 40px;
  -webkit-appearance: none;
  background: url("select.0cd151cc.webp") 100% no-repeat;
  border: none;
  font-size: 26px;
  font-weight: 600;
  position: absolute;
  left: 30px;
}

.phone-prefix__code {
  height: 100%;
  text-align: left;
  color: #fff;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  font-family: Roboto Condensed;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.25;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
  position: absolute;
  top: 0;
  left: 50px;
}

.phone-input {
  padding: 10px 3px 9px 160px;
}

.email-input {
  letter-spacing: .8px;
}

.email-input, .password-input, .promocode-input {
  padding-left: 20px;
}

.password-control.view {
  background: url("no-view.aaec82a7.svg") center no-repeat;
}

.inp-form::-webkit-input-placeholder {
  opacity: .5;
  color: #fff;
  font-weight: 10;
}

.inp-form::-ms-input-placeholder {
  opacity: .5;
  color: #fff;
  font-weight: 10;
}

.inp-form::placeholder {
  opacity: .5;
  color: #fff;
  font-weight: 10;
}

.currency__inp {
  background-color: #012347;
  padding: 10px 10px 9px 74px;
  position: relative;
}

.currency__inp--email {
  margin-left: 0;
}

.formError {
  color: #d63333;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding-top: 7px;
  padding-left: 0;
  font-size: 12px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
}

.form-btn:hover {
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
  -webkit-box-shadow: 0 11px 27px rgba(112, 48, 213, .15);
  box-shadow: 0 11px 27px rgba(112, 48, 213, .15);
}

.select2-container {
  position: absolute;
}

.select2-container--default {
  position: absolute;
  top: 19px;
  left: 0;
}

.phone-prefix__select + .select2-container {
  left: 20px;
  height: 100% !important;
  top: 0 !important;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  width: 30px;
  height: 30px;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
}

.select2-container--default .select2-selection--single {
  background: none;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
}

img.undefined {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  top: inherit;
  margin-top: inherit;
  margin-left: inherit;
  width: inherit;
  height: inherit;
  border-width: inherit;
  border-style: inherit;
  border-color: inherit;
  -webkit-appearance: none;
  background: url("select.0cd151cc.webp") 100% no-repeat;
  left: 0;
}

.phone-prefix__select + .select2-container--default .select2-selection--single .select2-selection__arrow {
  left: 110px;
  height: 100% !important;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-width: inherit;
  border-color: inherit;
  -webkit-appearance: none;
  background: url("select.0cd151cc.webp") 100% / 12px no-repeat;
  left: 7px;
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.list-select + .select2 {
  height: 100%;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
  top: 0;
  left: auto;
  right: 0;
  width: 100% !important;
}

.list-select + .select2 .select2-selection__rendered {
  color: #fff;
  padding-left: 30px;
  font-size: 15px;
  font-weight: 300;
  position: relative;
}

.list-select + .select2 .select2-selection--single {
  width: 100%;
  height: 100% !important;
}

.phone-prefix__select + .select2-container--default {
  position: absolute;
  top: 44px;
}

.form__checkbox {
  font-size: 15px;
}

.overlay-bg {
  z-index: -30;
  width: 100%;
  height: 100%;
  position: absolute;
}

.white-text {
  text-shadow: 0 0 5px #fff !important;
  color: #fff !important;
}

.list-select, .currency__img {
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.tab.active {
  border-bottom: 2px solid #ffd407;
}

.register__img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.register__img-pic {
  position: absolute;
}

.register__img-pic--one {
  width: 40%;
  top: 150px;
  left: 540px;
}

.form__label {
  margin-bottom: 4px;
  font-size: 16px;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: inline-flex;
}

.form__checkbox-item {
  opacity: 0;
  z-index: -1;
  position: absolute;
}

.form__checkbox-item + .form__label {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-box-align: start;
  -moz-box-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: inline-flex;
}

.form__checkbox-item + .form__label:before {
  width: 1em;
  height: 1em;
  content: "";
  background-position: center;
  background-repeat: no-repeat;
  background-size: 65% 65%;
  border: 1px solid #3c3c4d;
  -webkit-border-radius: .25em;
  border-radius: .25em;
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  -webkit-flex-grow: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  margin-right: .5em;
  display: inline-block;
}

.form__checkbox-item:checked + .form__label:before {
  width: 1em;
  height: 1em;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACnSURBVHgBnZPBDYMwDEWtTlBx6jZlAQ6dgG4CR6BlDcQWvsA8GeETS7FEAgTIk3yx/X44GCIHgNwW2zI4RuYlrbGNGveoVfwijZzcp6TAdHWzaX7IsheGYdSWedAF2vZPXdeH7af3sqSukr0XpWyIN/NkXRLhTNzIsqDLRfGJihs5DIiJKpujgJioMu8NpmnGCaw3nUKpJ1rhHlX4c8iNc0Qwbv5WZwElzs7zDBY0hQAAAABJRU5ErkJggg==");
  background-size: cover;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.form__checkbox-item:required {
  display: none;
}

.form__checkbox-item:required:invalid + .form__label:before {
  border: 1px solid #ff2768;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.form__label--rules {
  color: #9b9bb2;
  max-width: 300px;
  margin: 0;
}

@media screen and (max-width: 1920px), (min-width: 1920px) {
  .list-select + .select2 .select2-selection--single {
    height: 40px;
  }

  .list-select + .select2 .select2-selection__rendered {
    padding-left: 20px;
    font-size: 16px;
    font-weight: 300;
  }

  .phone-prefix__code {
    height: 100%;
    -webkit-box-align: center;
    -moz-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    font-size: 15px;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: flex;
    top: 0 !important;
  }

  .phone-prefix__select + .select2-container--default {
    top: 24px;
  }

  .phone-prefix__select + .select2-container {
    left: 13px;
  }

  .phone-input {
    padding: 9px 3px 9px 125px;
  }

  .select2-container .select2-selection--single .select2-selection__rendered {
    width: 24px;
    height: 24px;
  }

  .phone-prefix__select + .select2-container--default .select2-selection--single .select2-selection__arrow {
    left: 80px;
  }
}

@media screen and (max-width: 959px) {
  .register__wrapper {
    min-width: 355px;
  }

  .register__bonus-text {
    font-size: 13px;
  }
}

@media screen and (max-width: 768px) {
  .form-wrapper {
    max-width: 370px;
  }

  .bonus {
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .bonus__img {
    width: 145px;
  }

  .bonus__item {
    max-height: 65px;
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    -webkit-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .register__wrapper {
    max-width: 370px;
    min-width: unset;
    min-width: 340px;
    -webkit-border-radius: 20px;
    border-radius: 20px;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
    padding: 20px;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: flex;
  }

  .form__wrapper {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: flex;
  }

  .register__desc {
    padding-bottom: 25px;
  }

  .register__desc-title {
    font-size: 30px;
    display: block;
  }

  .register__desc-price {
    font-size: 40px;
  }

  .register__desc-bg {
    font-size: 26px;
  }

  .register__desc-text {
    font-size: 15px;
  }

  .tabs__wrapper {
    width: 280px;
    height: 40px;
  }

  .tab {
    font-size: 16px;
  }

  .form__wrapper > .form__row {
    margin-top: 25px;
  }

  .form__row {
    margin-top: 35px;
  }

  .inp-form {
    font-size: 16px;
  }

  .img-mail {
    width: 15px;
    height: 12px;
  }

  .img-phone {
    width: 15px;
    height: 16px;
  }

  .email__img {
    width: 16px;
    height: 13px;
    top: 20px;
    left: 20px;
  }

  .password__img {
    width: 16px;
    height: 16px;
    top: 17px;
    left: 20px;
  }

  .password-control {
    width: 16px;
    right: 23px;
  }

  .currency__img {
    width: 16px;
    height: 14px;
    left: 20px;
  }

  .list-select {
    width: 170px;
    left: 50px;
  }

  .botom-pop {
    margin-top: 28px;
  }

  .phone-prefix__code {
    font-size: 15px;
  }

  .form-btn {
    height: 60px;
    font-size: 20px;
  }

  .list-select + .select2 .select2-selection--single {
    height: 12px;
  }

  .list-select + .select2 .select2-selection__rendered {
    padding-left: 20px;
    font-size: 16px;
  }

  .phone-prefix__code {
    font-size: 16px;
    top: 22px;
    left: 50px;
  }

  .phone-prefix__select + .select2-container--default {
    top: 12px;
  }

  .phone-prefix__select + .select2-container {
    left: 13px;
  }

  .phone-input {
    padding: 10px 3px 9px 100px;
  }

  .select2-container .select2-selection--single .select2-selection__rendered {
    width: 20px;
    height: 20px;
  }

  .phone-prefix__select + .select2-container--default .select2-selection--single .select2-selection__arrow {
    left: 55px;
  }

  .tab.active:before, .tab.active:after {
    bottom: -22px;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow b {
    background-size: 10px;
    left: 7px;
  }

  .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    -webkit-transform-origin: 75%;
    -moz-transform-origin: 75%;
    -ms-transform-origin: 75%;
    transform-origin: 75%;
    background-size: 10px;
  }
}

@media screen and (max-width: 390px) {
  .register__desc-title {
    padding: 10px 0;
    font-size: 28px;
  }

  .register__bonus-text {
    font-size: 14px;
  }

  .register__wrapper {
    max-width: 295px;
    min-width: 100%;
  }

  .phone-input {
    padding: 10px 3px 9px 105px;
  }

  .register__wrapper {
    max-width: 310px;
  }

  .register__desc-text {
    max-width: 230px;
    margin: 2px auto 15px;
  }

  .form__label--rules {
    font-size: 14px;
  }

  .form-btn {
    font-size: 16px;
  }

  .tab__text:lang(bn) {
    white-space: nowrap;
    padding-left: 8px;
  }

  .tab:lang(bn) {
    white-space: nowrap;
    font-size: 12px;
  }

  .tab__text:lang(en) {
    white-space: nowrap;
    padding-left: 8px;
  }

  .tab:lang(en) {
    white-space: nowrap;
    font-size: 15px;
  }

  .tab__text:lang(uz) {
    padding-left: 8px;
  }

  .tab:lang(uz) {
    white-space: nowrap;
    font-size: 11px;
  }
}

@media screen and (max-width: 350px) {
  .bonus__img {
    width: 105px;
  }
}

@media screen and (width: 320px) {
  .register__wrapper {
    max-width: 275px;
  }
}

@media screen and (max-height: 800px) {
  .tabs__wrapper {
    max-height: 54px;
  }

  .form__row {
    margin-top: 16px;
  }

  .botom-pop {
    margin-top: 12px;
    padding-bottom: 0;
  }
}

@media screen and (min-width: 876px) and (max-height: 850px) {
  .register__wrapper {
    margin-bottom: 10px;
  }

  .register__bonus--deposit {
    margin-top: 14px;
    margin-bottom: 15px;
  }
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .register__wrapper {
    width: 100%;
  }

  .inp-form {
    width: auto;
    min-width: 310px;
  }
}

.wheel.bonus1-overlay:before {
  background-image: url("wheel-overlay.38ec0a24.webp");
}

.wheel.bonus2-overlay:before {
  background-image: url("wheel-overlay2.16fc62b1.webp");
}

.wheel__button {
  font-size: 21px;
}

@media screen and (max-width: 500px) {
  .wheel__button {
    font-size: 14px;
  }
}

@media screen and (max-width: 450px) {
  .wheel__button {
    font-size: 13px;
  }
}

/*# sourceMappingURL=index.e7d732ce.css.map */
