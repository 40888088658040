@import "/node_modules/select2/dist/css/select2.min.css";
@import "../../../shared/css/register.scss";
@import "../../shared/css/style.scss";
@import "../../shared/css/media.scss";
@import "../../shared/css/form.scss";
@import "../../shared/css/form_media.scss";

.wheel.bonus1-overlay::before {
	background-image: url("../img/wheel-overlay.png?as=webp");
}

.wheel.bonus2-overlay::before {
	background-image: url("../img/wheel-overlay2.png?as=webp");
}

.wheel__button {
	font-size: 21px;
}

@media screen and (max-width: 500px) {

	.wheel__button {
		font-size: 14px;
	}

}

@media screen and (max-width: 450px) {

	.wheel__button {
        font-size: 13px;
    }

}