* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  min-height: 100%;

  background-repeat: no-repeat;
  background-size: cover;
  scroll-behavior: smooth;
}

.page {
  display: flex;
  position: relative;
  flex-direction: column;
  margin: 0;
  min-height: 100vh;
  background: #121218;
  overflow-x: hidden;
  color: white;
  font-family: "Roboto Condensed", sans-serif;
}


body::-webkit-scrollbar {
  width: 1vmin;
}

body::-webkit-scrollbar-track {
  background: #dde4ec;
}

body::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background: #9537DF;
}


.header {
  display: flex;
  justify-content: center;
}

.header__logo {
  padding: 40px 0 20px 0;
}


.main {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  align-self: center;
  width: 100%;
  max-width:1350px;
}


.main__title {
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  font-size: 55px;
  font-size: clamp(1.75rem, 0.4844rem + 5.6250vw, 3.4375rem);
  z-index: 2;
}

.main__subtitle {
  padding: 0 10px;
  text-align: center;
  text-transform: uppercase;
  line-height: 29px;
  color: #FFD802;
  font-size: 25px;
  font-size: clamp(1.1875rem, 0.9063rem + 1.2500vw, 1.5625rem);
  font-weight: 700;
  z-index: 2;
}

.main__counter {
  z-index: 2;
  margin-top: 40px;
  margin-bottom: 25px;
  border-radius: 6px;
  box-shadow: 0px 0px 40px rgb(0 0 0 / 25%);
  background: #3C3C4D;
  padding: 10px 20px;
  text-align: center;
  color: #9B9BB2;
  font-size: 16px;
  font-weight: 400;

}



.main__bg-elem {
  pointer-events: none;
  /* position: absolute; */
  /*10.06*/
  min-height: 100%;
  width: 100%;
  /* overflow: hidden; */
}

.main__bg-shape {
  position: absolute;
  animation: float 6s infinite linear;
}

.main__bg-shape--one {
  left: 0;
  top: 0;
}

.main__bg-shape--two {
  top: 40%;
  left: -5%;
}

.main__bg-shape--three {
  bottom: 0;
  left: 0;
}

.main__bg-shape--four {
  top: 20%;
  right: 0;
}

.main__bg-shape--five {
  top: 50%;
  right: 0;
}

.main__bg-shape--six {
  bottom: -5%;
  right: 0;
}


.main__bg-shapes--mobile {
  display: none;
}

.bonus__item--accent {
  background: linear-gradient(180deg, #9437E0 0%, #5E35F5 100%) !important;
}

.form-bg {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  opacity: 0;
  z-index: -10000;
  width: 100%;
  min-height: 100vh;
  min-height: 100%;
  background: rgba(12, 17, 29, 0.7);
  padding: 0 15px;
  backdrop-filter: blur(12px);
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

/* wheel  */

.wheel {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.wheel-wrapper {
  display: flex;
  position: relative;
  align-items: center;
  /* flex: 0 0 605px; */
  align-self: center;
  justify-content: center;
  max-width: 566px;
  height: auto;
  background-image: url("../../shared/img/wheel-border.png?as=webp");
  background-position: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px;
  overflow: hidden;
}

.wheel-wrapper::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../shared/img/wheel-lights.png?as=webp");
  background-position: center;
  background-size: cover;
  animation: blink 0.6s infinite linear;
  content: "";
}

.wheel__marker {
  position: absolute;
  top: 3px;
  z-index: 3;
  width: 44px;
  filter: drop-shadow(0px 4px 8px white);
}



.wheel__item {
  z-index: 1;
  width: 100%;
  height: 100%;
}

.wheel__button {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  align-items: center;
  justify-content: center;
  z-index: 2;
  margin: 40px auto;
  margin: 0;
  width: 28%;
  height: 28%;
  outline: none;
  border: none;
  border-radius: 50%;
  background: linear-gradient(180deg, #FFD600 0%, #FFBA00 100%);
  box-shadow: 0px 9.77049px 41.8735px 4.18735px rgba(255, 212, 7, 0.66);
  padding: 15px;
  padding: 0;
  cursor: pointer;
  transform: translate(-50%, -50%);
  text-align: center;
  text-align: center;
  text-transform: uppercase;
  text-transform: uppercase;
  line-height: 29px;
  letter-spacing: 0;
  color: #121218;
  font-family: 'Roboto Condensed';
  font-size: 21px;
  font-size: 24.9437px;
  font-weight: bold;
  font-weight: 900;
  font-style: normal;
}

/* wheel  end*/

.main__logo {
  margin-bottom: 41px;
  width: 100%;
  max-width: 123px;
}

.btn {
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "Roboto Condensed", sans-serif;
}


.notification {
  box-sizing: border-box;
  display: flex;
  position: fixed;
  bottom: -50%;
  left: 50%;
  align-items: center;
  justify-content: space-between;
  z-index: 15;
  min-width: 550px;
  max-height: 99px;
  border-radius: 5px;
  background: linear-gradient(180deg, #38BA6D 0%, #2273B9 100%);
  padding-right: 55px;
  overflow: hidden;
  transform: translateX(-50%);
}

.notification__btn {
  border-radius: 5px;
  box-shadow: 0px 10px 20px rgba(255, 212, 7, 0.15);
  background: linear-gradient(180deg, #FFD600 0%, #FFBA00 100%);
  padding: 13px 49px;
  text-transform: uppercase;
  color: #121218;
  font-size: 16px;
  font-weight: 700;
}

.notification__img {
  object-fit: contain;
  object-position: -25px -10px;
}

.notification__text {
  padding-right: 50px;
  text-transform: uppercase;

}

.notification__subtitle {
  font-size: 13px;
  font-weight: 400;
}

.notification__title {
  margin-bottom: 5px;
  line-height: 100%;
  white-space: nowrap;
  font-size: 20px;
  font-weight: 700;
  z-index: 2;
}


@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.form-wrapper {
  width: 100%;
  max-width: 534px;
}

.bonus {
  display: flex;
  padding-top: 15px;
  padding-bottom: 30px;
  gap: 10px;
}



.bonus__img {
  margin-right: 15px;
  width: 90px;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.bonus__img--left {
  background-image: url("../../shared/img/notif1.png?as=webp");
  background-position: 48% 73%;
}

.bonus__img--right {
  background-image: url("../../shared/img/notif2.png?as=webp");

}

.bonus__item {
  display: flex;
  position: relative;
  align-items: center;
  flex: 0 1 50%;
  height: 99px;
  border-radius: 5px;
  background: linear-gradient(180deg, #38BA6D 0%, #2273B9 100%);
}

.bonus__subtitle {
  text-transform: uppercase;
  font-size: 13px;
}

.bonus__title {
  text-transform: uppercase;
  font-size: 20px;
}

.no-padding {
  height: 100vh;
}

.notification.notification--active {
  bottom: 1%;
  opacity: 1;
  animation: 1s linear fadeInUp;
}

.wheel.bonus1-overlay::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  /*background-image: url("../img/wheel-overlay.png?as=webp");*/
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: 3;
}

.wheel.bonus2-overlay::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  /*background-image: url("../img/wheel-overlay2.png?as=webp");*/
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: 3;
  transform: rotate(-90deg);
}

.bonus1 {
  transition: all 4.5s cubic-bezier(0, -0.08, 0, .99);
  transform: rotate(1080deg);
}

.bonus2 {
  transition: all 4.5s cubic-bezier(0, -0.08, 0, .99);
  transform: rotate(2610deg);
}

.form-bg.showing {
  display: flex;
  animation: showing 1.5s linear forwards;
  opacity: 1;
  z-index: 1000;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    bottom: -50%;
  }

  to {
    opacity: 1;
    bottom: 3%;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}


@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes pulse {
  from {
    transform: scale(1) translate(-50%, -50%);
    box-shadow: 0px 7px 15px 3px rgba(255, 78, 1, 0.3);
  }

  to {
    transform: scale(1.1) translate(-50%, -50%);
    ;
    box-shadow: 0px 7px 30px 3px rgba(255, 78, 1, 0.66);
  }
}

@keyframes pulseBtn {
  from {
    box-shadow: 0px 7px 15px 3px rgba(255, 78, 1, 0.3);
  }

  to {
    box-shadow: 0px 7px 30px 3px rgba(255, 78, 1, 0.66);
  }
}

@keyframes float {
  0% {

    transform: translatey(0px);
  }

  50% {

    transform: translatey(-30px);
  }

  100% {
    transform: translatey(0px);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes showing {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes smoke {
  0% {
    opacity: 0;
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    transform: translate(0)
  }

  50% {
    opacity: 1
  }

  to {
    opacity: 0;
    -webkit-transform: translate(10%, -20%);
    -moz-transform: translate(10%, -20%);
    transform: translate(10%, -20%)
  }
}